import React from 'react'
import './Plans.css'
import {plansData} from '../../src/data/plansData'
import whitetick from'../../src/assets/whiteTick.png'
const Plans = () => {
  return (
   <div className="plans-container"  id='plans'>
    <div className="blur plan-blur1"></div>
    <div className="blur plan-blur2"></div>
    <div className="programs-header" style={{
        gap:'2rem',
        display:'flex',
        color: 'white',
        fontSize:'3rem',
        fontWeight: 'bold',
        justifyContent: 'center'
    }} >
            <span className='stroke-text' >READY TO START</span>
            <span>YOUR JOURNEY</span>
            <span className='stroke-text' >NOW WITHUS</span>
    </div>
    {/* plans card */}
    <div className="plans">
{plansData.map((plan,i)=>(
<div className="plan" key={i}>
    {plan.icon}
    <span>{plan.name}</span>
    <span>${plan.price}</span>
    <div className="features">
        {plan.features.map((feature,i)=>(
          <div className="feature">
            <img src={whitetick} alt="" className="src" />
            <span key={i}>{feature}</span>
          </div>  
        ))}
    </div>
    <div ><span>see more benefits -></span></div>
   <button className="btn">Join Now</button>
     </div>
))}

    </div>
   </div>

  )
}

export default Plans
