import React from 'react'
import './Hero.css'
import Header from './Header'
import Heart from '../assets/heart.png'
import hero_image from '../assets/hero_image.png'
import hero_image_back from '../assets/hero_image_back.png'
import Calories from '../assets/calories.png'
import NumberCounter from 'number-counter'
const Hero = () => {
  const mobile=window.innerWidth<=768 ? true: false;
  return (
    <div className='hero' id='home'>
      <div className="blur hero-blur"></div>
<div className="left-h">
  {/* Header part or Navbar*/}
  <Header/>

  {/* add */}
  <div className="ad">
    <div>
      {/* ornage color div */}
    </div>
    <span>the best fitnessclub in the town</span>
  </div>
  {/* content */}
  <div className="Hero-text">
    <div>
    <span className='stroke-text'>Shape </span>
    <span>your</span>
    </div>
   <div>
    Ideal Body
   </div>
   <div><span>In here we will help you to shape and build your ideal body and live up your life to fullest</span></div>
  </div>
{/* figures */}
<div className="figures">
  <div>
    <span><NumberCounter end={140} start={100} delay='4'preFix="+"/></span>
    <span>Expert Coaches</span>
    
    </div>
  <div>
    <span><NumberCounter end={975} start={800} delay='4'preFix="+"/></span>
    <span>Memebrs Joined</span>
    
    </div>
  <div>
    <span><NumberCounter end={50} start={0} delay='4'preFix="+"/></span>
    <span>Fitness programms</span>
    </div>


</div>
{/* hero buttons */}
<div className="hero-buttons">
  <button className='btn'>Get Started</button>
  <button className='btn'>Learn more</button>
</div>
</div>
<div className="right-h">
<button className='btn'>Join Now</button>
<div className="heart-rate">
  <img src={Heart} alt="" className="src" />
<span>Heart Rate </span>
<span> 116 bpm</span>
</div>
<img src={hero_image} alt="" className='hero_image' />
<img src={hero_image_back} alt="" className='hero_image_back' />
<div className="calories">
  <img src={Calories}alt="" className="src" />
  <div>
  <span>Calories burned
  </span>
  <span>220 kcal</span>
  </div>
</div>
    </div>
    </div>
  )
}

export default Hero
