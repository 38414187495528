import React from 'react';
import './Reason.css';
import image1 from '../../src/assets/image1.png';
import image2 from '../../src/assets/image2.png';
import image3 from '../../src/assets/image3.png';
import image4 from '../../src/assets/image4.png';
import nb from '../../src/assets/nb.png';
import adidas from '../../src/assets/adidas.png';
import nike from '../../src/assets/nike.png';
import tick from '../../src/assets/tick.png';

const Reason = () => {
  return (
    <div className="reasons" id='reasons'>
      <div className="left-r">
        <img src={image1} alt="" className="src" />
        <img src={image2} alt="" className="src" />
        <img src={image3} alt="" className="src" />
        <img src={image4} alt="" className="src" />
      </div>
      <div className="right-r">
      <span >some reasons</span>
      <div>
        <span className='stroke-text'>why</span>
      <span>choose us ?</span>
      <div>
      <div className='details-r'>
        <div>
        <img src={tick} alt=""></img>
        <span>over 140+ expert coachs</span>
      </div>
      <div>
        <img src={tick} alt="" />
        <span>train smarter and faster than before</span>
      </div>
      <div>
        <img src={tick} alt="" />
        <span>1 free program for new member</span>
      </div>
      <div>
        <img src={tick} alt="" />
        <span>reliable partners</span>
      </div>
      </div>
      <span style={{
  color: "var(--gray)",
  fontWeight: 'normal',
  fontSize: '1.1rem',
}}>Our partners</span>
<div className="partners">
<img src={nb} alt=""/>
<img src={adidas} alt="" />
  <img src={nike} alt="" />
</div>
      </div>
      </div>
      </div>
    </div>
  );
}

export default Reason;
