import './App.css';
import Footer from './components/Footer';
 import Hero from './components/Hero';
import Plans from './components/Plans';
import Programms from './components/Programms';
import Reason from './components/Reason';

function App() {
  return (
    <div className="App">
       <Hero/>  
       <Programms/>
       <Reason/>
       <Plans/>
       <Footer/>
    </div>
  );
}

export default App;
