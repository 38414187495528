import React from 'react'
import './Footer.css'
import github from '../../src/assets/github.png'
import instagram from '../../src/assets/instagram.png'
import linkedin from '../../src/assets/linkedin.png'
import logo from '../../src/assets/logo.png'

const Footer = () => {
  return (
  <div className="footer-container">
    <hr/>
    <div className="footer">
        <div className="social-links">
        <img src={github} alt="" />
        <img src= {instagram} alt="" />
        <img src={linkedin}alt="" />
        </div>
    <div className="logo-f">
<img src={logo} alt="" className="src" />
</div>
    </div>
  </div>
  )
}

export default Footer
