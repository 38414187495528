import React, { useState } from 'react';
import logo from "../assets/logo.png";
import bars from "../assets/bars.png";
import { Link } from 'react-scroll';
import './Header.css';

const Header = () => {
  const mobile = window.innerWidth <= 768 ? true : false;
  const [menuOpened, setmenuOpened] = useState(false);
  return (
    <div className="header">
      <img src={logo} alt="logo" className="logo" />
      {menuOpened === false && mobile === true ? (
        <div
          onClick={() => setmenuOpened(true)}
        >
          <img src={bars} alt="" className="src" style={{
            width: '1.5rem',
            height: '1.5rem', position: 'relative', bottom: '2.5rem'
          }} />
        </div>
      ) : (
        <ul className='header-menu'>
          <li >
            <Link
              onClick={() => setmenuOpened(false)}
              to='home'
              span={true}
              smooth={true}
            >  Home </Link>
          </li>
          <li>
            <Link
              onClick={() => setmenuOpened(false)}
              to='programs'
              span={true}
              smooth={true}
            >

              Programs </Link>
          </li>
          <li >
            <Link
              onClick={() => setmenuOpened(false)}
              to='reasons'
              span={true}
              smooth={true}
            >
              Why Us
            </Link></li>
          <li >
            <Link
              onClick={() => setmenuOpened(false)}
              to='plans'
              span={true}
              smooth={true}
            >
              Plans
            </Link> </li>
        </ul>
      )}
    </div>
  );
};
export default Header;
