import React from 'react';
import './Programms.css';
import { programsData } from "../data/programsData";
import RightArrow from '../assets/rightArrow.png';

const Programms = () => {
  return (
    <div className="Programs" id='programs'>
      <div className="program_header">
        <span className='stroke-text'>Explore our </span>
        <span>Programs</span>
        <span className='stroke-text'>to shape you</span>
      </div>
      <div className="program-catgories">
        {programsData.map((program, index) => (
          <div className="category" key={index}>
            {program.image} 
            <span>{program.heading}</span>
            <span>{program.details}</span>
            <div className="join-now">
              <span>Join now</span> 
              <img src={RightArrow} alt="" className="src" />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Programms;
